import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { EndOrderDetails, Order, TypesOfShippings, Address } from '@pedix-workspace/utils';
import {
  getSanitizedCustomFieldDetails,
  OrderItemsByCategories,
  hasAnyPriceModifier,
  getEndOrderToOrderTotalParams,
  OrderItem,
  getPaymentUrl,
} from '@pedix-workspace/orders';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { EnvironmentService, WhatsappLinkService } from '@pedix-workspace/angular-utils';

import { OrderTotalDetailsComponent } from '../total-details/total-details.component';

import { CommonModule, DatePipe, NgTemplateOutlet } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  IconClockComponent,
  IconCouponsComponent,
  IconFormComponent,
  IconLoadingComponent,
  IconPaymentComponent,
  IconShippingComponent,
  IconWhatsappComponent,
} from '@pedix-workspace/angular-ui-icons';
import { CurrencyFormatPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { format } from 'date-fns';
import { CartItemsListComponent } from '../cart-items-list/cart-items-list.component';
import { ResumeItemComponent } from '@pedix-workspace/pedixapp-shared-catalog';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { OrderShippingStatusComponent } from '../order-shipping-status/order-shipping-status.component';

export type OrderListDetailRenderEvent = {
  row: Order;
  height: number;
};

@Component({
  selector: 'pxw-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgTemplateOutlet,
    OrderTotalDetailsComponent,
    IconClockComponent,
    IconWhatsappComponent,
    IconPaymentComponent,
    IconShippingComponent,
    IconCouponsComponent,
    IconFormComponent,
    IconLoadingComponent,
    CurrencyFormatPipe,
    AlertComponent,
    ModalDialogComponent,
    DatePipe,
    CartItemsListComponent,
    ResumeItemComponent,
    ButtonComponent,
    OrderShippingStatusComponent,
    CommonModule,
  ],
})
export class OrderDetailsComponent implements OnChanges {
  @ViewChild('totalDetailsDialog') totalDetailsDialog: ModalDialogComponent;
  @ContentChild('preCustomerTemplate') preCustomerTemplate: TemplateRef<unknown> | null = null;

  @Input() orderDetails: EndOrderDetails;
  @Input() loading = false;
  @Input() displayCustomerInfo = false;
  @Input() displaySku = false;

  @Input() isShippingCanceled = signal(false);
  @Input() isShippingConfirm = signal(false);
  @Input() isShippingCreate = signal(false);

  @Output() shippingCanceled = new EventEmitter();
  @Output() shippingConfirm = new EventEmitter();
  @Output() createShipping = new EventEmitter();

  formattedDate: string;
  whatsappLink: string;
  whatsappTarget: string;
  isDelivery: boolean;
  address: string;
  addressLink: string | undefined;
  customFields: ReturnType<typeof getSanitizedCustomFieldDetails>;
  orderItems: OrderItemsByCategories;
  displayTotalDetails: boolean;
  paymentUrl: string;

  private whatsappLinkService = inject(WhatsappLinkService);
  private analyticsService = inject(AnalyticsService);
  private environmentService = inject(EnvironmentService);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['orderDetails']?.currentValue ||
      changes['orderDetails'].currentValue === changes['orderDetails'].previousValue
    ) {
      return;
    }

    this.formattedDate = format(this.orderDetails.created, 'dd MMMM yyyy - hh:mm') + 'hs';

    this.whatsappLink = this.whatsappLinkService.generateLink({
      phone: String(this.orderDetails.personalInformation.phone),
    });
    this.whatsappTarget = this.whatsappLinkService.getWhatsappLinkHttpTarget();
    this.isDelivery = this.orderDetails.shippingOption?.type === TypesOfShippings.DELIVERY;
    this.address = this.getAddress(this.orderDetails.personalInformation.address);
    this.addressLink = this.orderDetails.personalInformation.address?.addressDetails?.url;
    this.displayTotalDetails = hasAnyPriceModifier(
      getEndOrderToOrderTotalParams(this.orderDetails),
    );
    if (this.orderDetails.id && this.orderDetails.paymentLink) {
      this.paymentUrl = getPaymentUrl(this.environmentService.siteUrl, this.orderDetails.id);
    }

    if (this.orderDetails.customFieldDetails) {
      this.customFields = getSanitizedCustomFieldDetails(this.orderDetails.customFieldDetails);
    }
  }

  getAddress(address: Address): string {
    return (address.addressDetails ? address.addressDetails.fullAddress : address.street) || '';
  }

  showTotalDetails() {
    this.totalDetailsDialog.open().catch();
  }

  onWhatsappClick() {
    this.analyticsService.logEvent('orders_whatsapp');
  }

  trackByOrderItem(index: number, orderItem: OrderItem) {
    return orderItem.cartItem.id;
  }

  get canCancelShipping(): boolean {
    if (!this.orderDetails?.shippingProvider?.providerName) {
      return false;
    }

    const providerStatus = this.orderDetails.shippingProvider.status;
    const providerName = this.orderDetails.shippingProvider.providerName;

    if (providerName === 'correoargentino') {
      return false;
    }

    const CANCELABLE_STATUSES = {
      pedidosya: ['CONFIRMED'],
      puniya: ['CONFIRMED'],
      rapiboy: ['CONFIRMED', 'IN_PROGRESS'],
      cabify: ['CONFIRMED'],
    } as Record<string, string[]>;

    return CANCELABLE_STATUSES[providerName]?.includes(providerStatus ?? '') || false;
  }

  cancelShipping() {
    this.shippingCanceled.emit();
  }
  onConfirmShipping() {
    this.shippingConfirm.emit();
  }
  onCreateShipping() {
    this.createShipping.emit();
  }
}
