import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { IconShippingComponent } from '@pedix-workspace/angular-ui-icons';
import { ShippingConfirmationResponseStatus } from '@pedix-workspace/utils';

@Component({
  selector: 'pxw-order-shipping-status',
  standalone: true,
  imports: [CommonModule, TagComponent, IconShippingComponent],
  templateUrl: './order-shipping-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderShippingStatusComponent implements OnChanges {
  @Input() status!: ShippingConfirmationResponseStatus;

  tagInfo: { type: any; label: string } = { type: 'default', label: 'Desconocido' };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status']) {
      const previousStatus = changes['status'].previousValue;
      const currentStatus = changes['status'].currentValue;

      if (currentStatus !== previousStatus) {
        this.updateTagInfo(currentStatus);
      }
    }
  }

  private updateTagInfo(status: ShippingConfirmationResponseStatus): void {
    switch (status) {
      case 'CONFIRMED':
        this.tagInfo = { type: 'info', label: 'Confirmado' };
        break;
      case 'CANCELLED':
        this.tagInfo = { type: 'danger', label: 'Cancelado' };
        break;
      case 'IN_PROGRESS':
        this.tagInfo = { type: 'warning', label: 'En camino' };
        break;
      case 'NEAR_PICKUP':
        this.tagInfo = { type: 'info', label: 'Cerca del punto de retiro' };
        break;
      case 'PICKED_UP':
        this.tagInfo = { type: 'success', label: 'Retirado' };
        break;
      case 'NEAR_DROPOFF':
        this.tagInfo = { type: 'info', label: 'Llegando al destino' };
        break;
      case 'COMPLETED':
        this.tagInfo = { type: 'success', label: '¡Listo! ' };
        break;
      default:
        this.tagInfo = { type: 'default', label: 'Estado desconocido' };
    }
  }
}
